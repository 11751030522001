<template>
  <div>
    <v-app class="authbackground">
      <app-notification />

      <v-container class="fill-height">
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="5"
            class="text-center"
          >
            <v-img
              src="/logo_white.svg"
              height="50"
              alt="SEDIWORK Logo"
            />
            <v-main class="mt-10">
              <slot />
            </v-main>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobileBrowser: true,
      updateAvailable: false,
    }
  },
  mounted() {
    this.mobileBrowser = this.$vuetify.display.mobile && !this.isNativePlatform
  },
}
</script>

<style lang="scss" scoped>
.authbackground {
  // background: linear-gradient(to bottom right, #5acf62, rgba(255, 153, 150, 0), #2F523E 97%),
  //    linear-gradient(to top right, white 10%, rgba(255, 153, 150, 0), white) #2F523E;
  background-image: url('/img/background/dashboard.png');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
</style>
